<template>
  <v-row class="align-end pa-2">
    <overlay :loading="loading" />
    <v-col cols="6">
      <v-row>
        <v-col cols="12" class="text-h5 black--text pb-0">Solicitações</v-col>
        <v-col cols="12" class="caption pt-0 grey--text"
          >Lista de solicitações enviadas por você</v-col
        >
      </v-row>
    </v-col>
    <v-col cols="6" class="d-flex justify-end">
      <v-btn @click="$router.push({ name: 'RequestServices' })" elevation="0" class="primary mr-4">
        <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
        Nova solicitação
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card class="elevation-0 rounded-0">
        <v-card-title class="mb-2">
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar por n° de solicitação"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2">
              <v-select
                v-model="searchStatus"
                :items="status"
                label="Filtrar Status"
                menu-props="auto"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <excel
                :data="requests"
                :json_fields="json_fields_services"
                icon="mdi-download"
                button_name="Exportar"
                file_name="solicitacao_de_servicos.xls"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="filteredServices"
          :search="searchStatus"
          :loading="!filteredServices"
          item-key="id"
          :custom-filter="filterOnlyNumber && filterOnlyText"
        >
          <template v-slot:[`item.status`]="{ item }">
            <div
              class="font-weight-bold"
              :style="'color: ' + item.status.color"
            >
              {{ item.status.text }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              height="30"
              dense
              @click="
                $router.push({
                  name: 'ViewRequest',
                  params: {
                    id: item.id,
                    data: item,
                  },
                })
              "
              elevation="0"
              class="primary"
            >
              Visualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Excel from "../../components/Excel/Excel.vue";
import Overlay from "../../components/Overlay/Overlay.vue";

export default {
  components: {
    Excel,
    Overlay
},
  data: () => ({
    loading: false,
    search: "",
    requests: [],
    searchStatus: null,
    status: [
      {
        text: "Filtrar por Status",
        value: null,
      },
      { text: "Em aberto", value: "opened" },
      { text: "Em análise", value: "under_analysis" },
      { text: "Concluído", value: "concluded" },
      { text: "Cancelado", value: "closed" }  ,
    ],
    json_fields_services: {
      Id: "id",
      Data: {
        field: "date",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.toString().split(" ").length; i++) {
            textFormatted += `${e.toString().split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Assunto: "subject",
      Status: "status.text",
      Prioridade: "priority",
      Descrição: {
        field: "description",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.toString().split(" ").length; i++) {
              textFormatted += `${e.toString().split(" ")[i]}\n`;
            }

            return textFormatted;
        },
      },
      Resposta: {
        field: "answer",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.toString().split(" ").length; i++) {
              textFormatted += `${e.toString().split(" ")[i]}\n`;
            }

            return textFormatted;
        },
      },
    },
  }),
  mounted() {
    this.showRequests();
  },
  methods: {
    async showRequests() {
      try {
        const response = await this.$axios("service_requests", {
          params: {
            doctor_id: this.currentUser.id,
          },
        });

        if (response.data) {
          response.data.map((service) => {
            this.requests = [
              {
                id: service.id,
                date: this.formatLastUpdate(service.updated_at),
                status: this.setStatus(service.status),
                priority: service.priority,
                description: service.description,
                subject: service.subject,
                // file: service.file ? service.file : null,
                answer: service.service_request_response || {},
              },
              ...this.requests,
            ];
          });
        }
      } catch (error) {
        const msg = error.response.error
        console.log("Show requests error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar as solicitações." + msg,
        });
      }
    },
    setStatus(value) {
      const status = {
        opened: {
          text: "Em aberto",
          value: "opened",
          color: "#feda15",
        },
        under_analysis: {
          text: "Em análise",
          value: "under_analysis",
          color: "#096CC8",
        },
        concluded: {
          text: "Concluído",
          value: "concluded",
          color: "#00AD45",
        },
        closed: {
          text: "Cancelado",
          value: "closed",
          color: "#CF4444",
        },
      };

      return (
        status[value] || {
          text: "Em aberto",
          color: "#feda15",
        }
      );
    },
    formatLastUpdate(date) {
      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    filterOnlyNumber(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "number" &&
        value.toString().indexOf(search) !== -1
      );
    },
    filterOnlyText(value, search) {
      const isDate = search.split("-");

      if (isDate.length > 1) search = `${isDate[2]}/${isDate[1]}/${isDate[0]}`;

      return (
        (value != null &&
          search != null &&
          typeof value === "string" &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !==
            -1) ||
        (typeof value === "object" &&
          value.value.toString().toLowerCase().indexOf(search.toLowerCase()) !==
            -1)
      );
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    filteredServices(){
      if (this.search) {
        return this.requests.filter(item => {
          return item.id == this.search
        })
      } else {
        return this.requests
      }
    },
    headers() {
      return [
        {
          text: "Nº da solicitação",
          value: "id",
          width: 250,
          align: "center",
        },
        { text: "Data", value: "date", width: 250, align: "center" },
        { text: "Status", value: "status", width: 250, align: "center" },
        { text: "Ação", value: "action", width: 250, align: "center" },
      ];
    },
  },
};
</script>

<style></style>
